<template></template>

<script>
import { getFingerprint, getFingerprintData } from '@thumbmarkjs/thumbmarkjs'
import { Store } from "../stores/store.js";
import { mapWritableState } from "pinia";
export default {
    computed: {
        ...mapWritableState(Store, ["visitorData", "authUser"]),
    },
    mounted() {
        let url =
            "https://m.ticto.app/unbUrrEQ60zLila3/uj5IYah8Pjcasguz?apiKey=eGz2r3xqstesBiBzxwkw";
        const fpPromise = import(
            "https://m.ticto.app/unbUrrEQ60zLila3/uj5IYah8Pjcasguz?apiKey=eGz2r3xqstesBiBzxwkw"
        ).then((FingerprintJs) =>
            FingerprintJs.load({
                scriptUrlPattern: url,
                endpoint:
                    "https://m.ticto.app/unbUrrEQ60zLila3/tGYx6BDTiZQWQkvH",
                apiKey: "eGz2r3xqstesBiBzxwkw",
                remoteControlDetection: true,
            }),
        );

        fpPromise
            .then((fp) =>
                fp.get({
                    extendedResult: true,
                }),
            )
            .then((result) => {
                // This is the visitor identifier:
                this.visitorData = result;

                console.log({ result });
                // attach default header with axios id
                axios.defaults.headers.common["X-FingerprintJS-Token"] =
                    this.visitorData.visitorId;
            });

            window.tracker = new EventTracker();
            window.tracker.load({ endpoint: 'https://fgpt.ticto.cloud/api/events' });
            window.tracker.track('custom_event', { ...this.authUser ? { linked_id: this.authUser.id } : { } });
    },
};
</script>

<template>
    <filters
        v-model="filters"
        @fetch="
            events = [];
            next_page_url = false;
            fetch();
        "
        date-filter
        :default-dates="defaultDates"
        class="mb-3"
    ></filters>

    <table-component
        :loading="loading"
        :pagination="pagination"
        :headers="[
            'Data',
            'Liberação',
            'Pedido',
            'Transação',
            'Lançamento',
            'Valor',
            'Saldo',
        ]"
        @fetch="fetch"
    >
        <template v-slot:tableHeader>
            <div class="mb-3 flex">
                <div class="ml-auto flex items-center">
                    <button
                        class="btn-primary-sm ml-auto mr-2"
                        :disabled="loading"
                        @click.prevent="exportStatement"
                    >
                        <ArrowDownTrayIcon
                            class="mr-1.5 h-5 w-5 flex-shrink-0 text-white"
                            aria-hidden="true"
                        />
                        Exportar
                    </button>
                    <span class="dark:text-white">
                        Exibindo {{ pagination.from }} a
                        {{ pagination.to }} registros de {{ pagination.total }}
                    </span>
                </div>
            </div>
        </template>
        <tr v-for="event in statementEvents">
            <td
                class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                {{ formatDate(event.event_date) }}
            </td>
            <td
                class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                {{ formatDateWithoutTime(event.event_properties.available_at) }}
            </td>
            <td
                class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                {{ event.event_properties.order_id ?? "-" }}
            </td>
            <td
                class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                {{ event.event_properties.transaction_hash ?? "-" }}
            </td>
            <td
                class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                {{ formatEventType(event) }}
            </td>
            <td
                class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                <span
                    class="cursor-default text-green-600 dark:text-green-200"
                    v-if="
                        event.event_properties.observation &&
                        event.event_properties.amount > 0
                    "
                    v-tooltip="event.event_properties.observation"
                >
                    + {{ formatCurrency(event.event_properties.amount) + "*" }}
                </span>

                <span
                    class="text-black-600 dark:text-white-200"
                    v-else-if="
                        event.event_properties.observation &&
                        event.event_properties.amount == 0
                    "
                    v-tooltip="event.event_properties.observation"
                >
                    {{ formatCurrency(event.event_properties.amount) + "*" }}
                </span>

                <span
                    class="cursor-default text-green-600 dark:text-green-200"
                    v-else-if="
                        event.event_properties.original_amount &&
                        event.event_properties.amount !=
                            event.event_properties.original_amount &&
                        event.event_properties.amount > 0
                    "
                    v-tooltip="showCompensationTooltip(event)"
                >
                    + {{ formatCurrency(event.event_properties.amount) + "*" }}
                </span>

                <span
                    class="text-black-600 dark:text-white-200"
                    v-else-if="
                        event.event_properties.original_amount &&
                        event.event_properties.amount !=
                            event.event_properties.original_amount &&
                        event.event_properties.amount == 0
                    "
                    v-tooltip="showCompensationTooltip(event)"
                >
                    {{ formatCurrency(event.event_properties.amount) + "*" }}
                </span>

                <span
                    class="cursor-default text-green-600 dark:text-green-200"
                    v-else-if="event.event_properties.is_undo_compensation"
                    v-tooltip="showUndoCompensationTooltip(event)"
                >
                    + {{ formatCurrency(event.event_properties.amount) + "*" }}
                </span>

                <span
                    class="cursor-default text-red-600 dark:text-red-200"
                    v-else-if="event.event_properties.from_user_id"
                    v-tooltip="showFromUserTooltip(event)"
                >
                    - {{ formatCurrency(event.event_properties.amount) + "*" }}
                </span>

                <span
                    class="text-green-600 dark:text-green-200"
                    v-else-if="
                        (event.event_properties.amount != 0 &&
                            event.event_class ==
                                'App\\Events\\Statement\\MoneyAdded') ||
                        event.event_class ==
                            'App\\Events\\Statement\\CashoutCancelled' ||
                        event.event_class ==
                            'App\\Events\\Statement\\DisputeBalanceRestored' ||
                        event.event_class ==
                            'App\\Events\\Statement\\MoneyUnblocked'
                    "
                >
                    + {{ formatCurrency(event.event_properties.amount) }}
                </span>

                <span
                    class="text-red-600 dark:text-red-200"
                    v-else-if="
                        (event.event_properties.amount != 0 &&
                            event.event_class ==
                                'App\\Events\\Statement\\MoneySubtracted') ||
                        event.event_class ==
                            'App\\Events\\Statement\\Cashout' ||
                        event.event_class ==
                            'App\\Events\\Statement\\ChargebackFee' ||
                        event.event_class ==
                            'App\\Events\\Statement\\RefundFee' ||
                        event.event_class ==
                            'App\\Events\\Statement\\MoneyBlocked'
                    "
                >
                    - {{ formatCurrency(event.event_properties.amount) }}
                </span>

                <span
                    class="text-black-600 dark:text-white-200"
                    v-else-if="event.event_properties.amount"
                >
                    {{ formatCurrency(event.event_properties.amount) }}
                </span>

                <span
                    class="text-black-600 dark:text-white-200"
                    v-else
                >
                    -
                </span>
            </td>
            <td
                class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                style="min-width: 450px"
            >
                <!-- Auditoria do extrato -->
                <span v-if="event.event_properties.total_balance">
                    <span>
                        Comissões Autorizadas:
                        {{ formatCurrency(event.event_properties.authorized_commissions_sum ?? "-") }}
                        <br>
                    </span>
                    <span>
                        Saques Aprovados:
                        {{ formatCurrency(event.event_properties.approved_cashouts_sum ?? "-") }}
                        <br>
                    </span>
                    <span>
                        Saldo Total (Comissões - Saques):
                        {{ formatCurrency(event.event_properties.total_balance ?? "-") }}
                        <br>
                    </span>
                    <span>
                        Saldo Pendente:
                        {{ formatCurrency(event.event_properties.pending_balance ?? "-") }}
                        <br>
                    </span>
                    <span>
                        Saldo Disponível:
                        {{ formatCurrency(event.event_properties.available_balance ?? "-") }}
                        <br>
                    </span>
                    <span>
                        Recebíveis Disponíveis:
                        {{ formatCurrency(event.event_properties.available_payables_sum ?? "-") }}
                        <br>
                    </span>
                    <span>
                        Diferença (Saldo Disp. - Recebíveis Disp.):
                        {{ formatCurrency(event.event_properties.diff ?? "-") }}
                    </span>
                </span>

                <!-- Demais eventos -->
                <span v-else>
                    <span>
                        Disponível:
                        {{ formatCurrency(event.event_properties.available_balance ?? "-") }}
                        <br>
                    </span>
                    <span>
                        Pendente:
                        {{ formatCurrency(event.event_properties.pending_balance ?? "-") }}
                    </span>
                </span>
            </td>
        </tr>
    </table-component>
</template>

<script>
import { ArrowDownTrayIcon } from "@heroicons/vue/24/outline";
import { useToast } from "vue-toastification";
import Filters from "../../../components/layouts/Filters.vue";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import HelperMixin from '../../../mixins/HelperMixin.vue';

export default {
    components: { TableComponent, Filters, ArrowDownTrayIcon },
    mixins: [HelperMixin],
    props: ["user"],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            loading: true,
            filters: {
                "filter[betweenDates]": `${moment()
                    .subtract(1, "month")
                    .format("DD/MM/YYYY")},${moment().format("DD/MM/YYYY")}`,
            },
            next_page_url: false,
            statementEvents: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            defaultDates: [
                moment().subtract(1, "month").format("DD/MM/YYYY"),
                moment().format("DD/MM/YYYY"),
            ],
        };
    },

    mounted() {
        this.fetch();

        Echo.channel("user-" + this.$route.params.user_id).listen(
            ".App\\Events\\BalanceUpdating",
            (e) => {
                this.balance_updating = e.updating;

                if (!e.updating) {
                    this.statementEvents = [];
                    this.next_page_url = false;
                    this.fetch();
                }
            },
        );
    },

    methods: {
        exportStatement() {
            this.loading = true;
            axios
                .get("/api/user/" + this.user.id + "/statement/export", {
                    params: this.filters,
                })
                .then(({ data }) => {
                    this.loading = false;
                    this.toast.success(
                        "Exportação iniciada com sucesso, você receberá o relatório por e-mail em alguns minutos.",
                    );
                });
        },
        fetch() {
            this.loading = true;

            const params = this.filters;
            params["page"] = this.pagination.current_page;
            params["include_release"] = true;

            axios
                .get("/api/user/" + this.user.id + "/statement", {
                    params: params,
                })
                .then(({ data }) => {
                    this.statementEvents = data.data;
                    this.next_page_url = data.data.next_page_url;

                    if (this.statementEvents.length > 0) {
                        this.pagination = {
                            total: data.total,
                            per_page: data.per_page,
                            current_page: this.pagination.current_page,
                            last_page: Math.ceil(data.total / data.per_page),
                            from: data.from,
                            to: data.to,
                        };
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        showCompensationTooltip(event) {
            if (
                event.event_properties.original_amount &&
                event.event_properties.amount !=
                    event.event_properties.original_amount
            ) {
                return `${this.formatCurrency(
                    event.event_properties.original_amount -
                        event.event_properties.amount,
                )} foi utilizado para compensar seu saldo negativo, ao todo essa venda comissionou ${this.formatCurrency(
                    event.event_properties.original_amount,
                )}.`;
            }
            return null;
        },
        showUndoCompensationTooltip(event) {
            if (event.event_properties.is_undo_compensation) {
                return `Esse valor estava retido para compensar um reembolso sacado e agora foi liberado, ao todo essa venda comissionou ${this.formatCurrency(
                    event.event_properties.original_amount,
                )}.`;
            }
            return null;
        },
        showFromUserTooltip(event) {
            if (event.event_properties.from_user_id) {
                if (event.event_properties.commission_type == "provider") {
                    return `Reembolso da comissão do ${this.formatCommissionType(
                        event,
                    ).toLowerCase()}.`;
                }
                return `Reembolso da comissão do ${this.formatCommissionType(
                    event,
                ).toLowerCase()} por ausência de saldo.`;
            }
            return null;
        },
        formatCurrency(value) {
            return (value / 100).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        formatEventType(event) {
            let text;
            switch (event.event_class) {
                case "App\\Events\\Statement\\MoneyAdded":
                    text = `Comissão de ${this.formatCommissionType(event)}`;
                    break;
                case "App\\Events\\Statement\\DisputeBalanceRestored":
                    text = `Disputa Vencida`;
                    break;
                case "App\\Events\\Statement\\ChargebackFee":
                    text = `Taxa de Chargeback`;
                    break;
                case "App\\Events\\Statement\\RefundFee":
                    text = `Taxa de Reembolso`;
                    break;
                case "App\\Events\\Statement\\Cashout":
                    text = `Saque #${event.event_properties.cashout_id}`;
                    break;
                case "App\\Events\\Statement\\CashoutCancelled":
                    text = `Saque #${event.event_properties.cashout_id} Cancelado`;
                    break;
                case "App\\Events\\Statement\\Anticipation":
                    text = `Antecipação #${event.event_properties.anticipation_id}`;
                    break;
                case "App\\Events\\Statement\\MoneyBlocked":
                    text = `Bloqueio de Saldo`;
                    break;
                case "App\\Events\\Statement\\MoneyUnblocked":
                    text = `Desbloqueio de Saldo`;
                    break;
                case "App\\Events\\Statement\\MoneyReleased":
                    text = `Liberação de Saldo`;
                    break;
                case "App\\Events\\Statement\\MoneySubtracted":
                    if (event.event_properties.status == "refunded") {
                        text = "Reembolso";
                    } else {
                        text = `Subtração de Saldo`;
                    }
                    break;
                case "App\\Events\\Statement\\BalanceAdjustment":
                    if (
                        event.event_properties.status == "undo_refund" &&
                        event.event_properties.type == "positive"
                    ) {
                        text = `Cancelamento de Reembolso`;
                    } else if (
                        event.event_properties.status == "undo_refund" &&
                        event.event_properties.type == "negative"
                    ) {
                        text = `Reembolso`;
                    } else {
                        text = `Ajuste de Saldo`;
                    }
                    break;
                case "App\\Events\\Statement\\ServiceChargeFee":
                    text = `Taxa de Serviço: ${this.formatServiceName(event)}`;
                    break;
                case "App\\Events\\Statement\\StatementAudit":
                    text = `Auditoria do Extrato`;
                    break;
                default:
                    text = "-";
                    break;
            }
            if (
                event?.event_properties?.has_many_payables
            ) {
                text += ` (Parcela ${event.event_properties.occurrence}/${event.event_properties.installments})`;
            }
            return text;
        },
        formatCommissionType(event) {
            switch (event.event_properties.commission_type) {
                case "ticto":
                    return "Ticto";
                case "affiliate":
                    return "Afiliado";
                case "manager":
                    return "Coprodutor";
                case "coproducer":
                    return "Coprodutor";
                case "provider":
                    return "Fornecedor";
                case "referral":
                    return "Indicação";
                case "producer":
                    return "Produtor";
                case "cashback":
                    return "Cashback";
                default:
                    return "Venda Padrão";
            }
        },
        formatServiceName(event) {
            switch (event.event_properties.service_name) {
                case "smart-player":
                    return "ViewAI";
                case "Smart player":
                    return "ViewAI";
                default:
                    return "ViewAI";
            }
        },
    },
};
</script>
